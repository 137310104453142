
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup(){
    const router: any = useRouter()

    const handleJump = (type: string) => {
      type === 'add' ? router.push('/localRegistration/focusAreaSet/dealerAdjustment/add') : router.push('/localRegistration/focusAreaSet/dealerAdjustment/delete')
    }

    return {
      handleJump
    }
  }
})
